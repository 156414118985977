import emailjs from "@emailjs/browser";

type Combinable = string | undefined;

const EMAILJS_SERVICE_ID: Combinable = process.env.EMAILJS_SERVICE_ID;
const EMAILJS_TEMPLATE_ID: Combinable = process.env.EMAILJS_TEMPLATE_ID;
const EMAILJS_PUBLIC_KEY: Combinable = process.env.EMAILJS_PUBLIC_KEY;

export async function sendEmail(
  email: string,
  fullName: string,
  subject: string,
  html: string
) {
  try {
    const templateParams = {
      from_email: email,
      name: fullName,
      subject: subject,
      html_content: html,
    };

    if (EMAILJS_SERVICE_ID && EMAILJS_TEMPLATE_ID) {
      await emailjs.send(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID,
        templateParams,
        {
          publicKey: EMAILJS_PUBLIC_KEY,
        }
      );
    }

    console.log("Email sent successfully");
  } catch (error) {
    console.error("Error sending email:", error);
    throw new Error("Failed to send email");
  }
}
