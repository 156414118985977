import { Project } from '../models/project';

type Listener<T> = (items: T[]) => void;

class State<T> {
  protected Listeners: Listener<T>[] = [];

  addListener(listener: Listener<T>) {
    this.Listeners.push(listener);
  }
}

export class ProjectState extends State<Project> {
  private forms: Project[] = [];
  private static instance: ProjectState;

  private constructor() {
    super();
  }

  static getInstance() {
    if (this.instance) {
      return this.instance;
    }
    this.instance = new ProjectState();
    return this.instance;
  }

  private notifyListeners() {
    for (const listenerFn of this.Listeners) {
      listenerFn(this.forms.slice());
    }
  }

  addForm(
    fullName: string,
    email: string,
    phoneNumber: string,
    designType: string,
    description: string,
    color: string,
    uploadFile: File,
    startDate: string,
    endDate: string,
    budgetCurrency: string,
    budgetAmount: number,
  ) {
    const newForm = new Project(
      Math.random().toString(),
      fullName,
      email,
      phoneNumber,
      designType,
      description,
      color,
      uploadFile,
      startDate,
      endDate,
      budgetCurrency,
      budgetAmount,
    );
    this.forms.push(newForm);
    this.notifyListeners();
  }
}

export const projectState = ProjectState.getInstance();
