import { AutoBind } from "../decorators/autobind";
import { projectState } from "../state/project-state";
import { sendEmail } from "../utils/email";
import { validate, Validatable } from "../utils/validation";

export class UserInput {
  fullNameInputElem: HTMLInputElement;
  emailInputElem: HTMLInputElement;
  phoneNumberInputElem: HTMLInputElement;
  designTypeSelectElem: HTMLSelectElement;
  descriptionTextareaElem: HTMLTextAreaElement;
  colorPrefInputElem: HTMLInputElement;
  uploadFileInputElem: HTMLInputElement;
  startDateInputElem: HTMLInputElement;
  endDateInputElem: HTMLInputElement;
  budgetCurrencyInputElem: HTMLSelectElement;
  budgetAmountInputElem: HTMLInputElement;

  constructor() {
    this.fullNameInputElem = document.getElementById(
      "name"
    ) as HTMLInputElement;
    this.emailInputElem = document.getElementById("email") as HTMLInputElement;
    this.phoneNumberInputElem = document.getElementById(
      "phone"
    ) as HTMLInputElement;
    this.designTypeSelectElem = document.getElementById(
      "design-type"
    ) as HTMLSelectElement;
    this.descriptionTextareaElem = document.getElementById(
      "description"
    ) as HTMLTextAreaElement;
    this.colorPrefInputElem = document.getElementById(
      "color"
    ) as HTMLInputElement;
    this.uploadFileInputElem = document.getElementById(
      "uploadFile"
    ) as HTMLInputElement;
    this.startDateInputElem = document.getElementById(
      "start-date"
    ) as HTMLInputElement;
    this.endDateInputElem = document.getElementById(
      "end-date"
    ) as HTMLInputElement;
    this.budgetCurrencyInputElem = document.getElementById(
      "currency"
    ) as HTMLSelectElement;
    this.budgetAmountInputElem = document.getElementById(
      "amount"
    ) as HTMLInputElement;

    this.config();
  }

  @AutoBind
  private config() {
    const submitBtn = document.querySelector(
      ".btn-submit"
    ) as HTMLButtonElement;
    if (submitBtn) {
      submitBtn.addEventListener("click", this.submitHandler);
    } else {
      console.error("Submit button not found"); // Error log
    }
  }

  private getUserInput(): any | void {
    const fullName = this.fullNameInputElem.value.trim();
    const email = this.emailInputElem.value.trim();
    const phoneNumber = this.phoneNumberInputElem.value.trim();
    const designType = this.designTypeSelectElem.value;
    const description = this.descriptionTextareaElem.value || "No description";
    const color = this.colorPrefInputElem.value || "No Color Provided";
    const uploadFile =
      this.uploadFileInputElem.files?.[0] || "No Upload File Provided";
    const startDate = this.startDateInputElem.value;
    const endDate = this.endDateInputElem.value;
    const budgetCurrency = this.budgetCurrencyInputElem.value;
    const budgetAmount = this.budgetAmountInputElem.value.trim();

    const fullNameValidate: Validatable = {
      value: fullName,
      required: true,
      minLength: 3,
    };

    const emailValidate: Validatable = {
      value: email,
      required: true,
    };

    const phoneNumberValidate: Validatable = {
      value: phoneNumber,
      required: true,
      min: 0,
    };

    const designTypeValidate: Validatable = {
      value: designType,
      required: true,
    };

    const startDateValidate: Validatable = {
      value: startDate,
      required: true,
    };

    const endDateValidate: Validatable = {
      value: endDate,
      required: true,
    };

    const budgetAmountValidate: Validatable = {
      value: +budgetAmount,
      required: true,
      min: 0,
    };

    if (
      !validate(fullNameValidate) ||
      !validate(emailValidate) ||
      !validate(phoneNumberValidate) ||
      !validate(designTypeValidate) ||
      !validate(startDateValidate) ||
      !validate(endDateValidate) ||
      !validate(budgetAmountValidate)
    ) {
      alert("Please fill out all the required fields marked with a star.");
    } else {
      return [
        fullName,
        email,
        phoneNumber,
        designType,
        description,
        color,
        uploadFile,
        startDate,
        endDate,
        budgetCurrency,
        +budgetAmount,
      ];
    }
  }

  @AutoBind
  private async submitHandler(event: Event) {
    event.preventDefault();
    const userInput = this.getUserInput();
    if (Array.isArray(userInput)) {
      const [
        fullName,
        email,
        phoneNumber,
        designType,
        description,
        color,
        uploadFile,
        startDate,
        endDate,
        budgetCurrency,
        budgetAmount,
      ] = userInput;
      // console.log(
      //   fullName,
      //   email,
      //   phoneNumber,
      //   designType,
      //   description,
      //   color,
      //   uploadFile,
      //   startDate,
      //   endDate,
      //   budgetCurrency,
      //   budgetAmount,
      // );
      projectState.addForm(
        fullName,
        email,
        phoneNumber,
        designType,
        description,
        color,
        uploadFile,
        startDate,
        endDate,
        budgetCurrency,
        budgetAmount
      );

      const subject = "Request for graphic design services";

      const html = `
      Full Name: ${fullName}
      Email: ${email}
      Phone Number: ${phoneNumber}
      Design Type: ${designType}
      Description: ${description}
      Color Preference: ${color}
      Start Date: ${startDate}
      End Date: ${endDate}
      Budget: ${budgetCurrency} ${budgetAmount}
      `;

      // send email
      try {
        await sendEmail(email, fullName, subject, html);
      } catch (error) {
        console.error("Error sending email:", error);
      }
    }
  }
}
