export class Project {
  constructor(
    public id: string,
    public fullName: string,
    public email: string,
    public phoneNumber: string,
    public designType: string,
    public description: string,
    public color: string,
    public uploadFile: File,
    public startDate: string,
    public endDate: string,
    public budgetCurrency: string,
    public budgetAmount: number,
  ) {}
}
