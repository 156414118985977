const scroller = document.querySelector(".scroller")! as HTMLElement;
type Combinable = boolean | string | null;

export function addAnimation() {
    let dataAnimated: Combinable = scroller.getAttribute("data-animated");

    if (typeof dataAnimated === "string") {
        dataAnimated = dataAnimated === 'true';
    }

    scroller.setAttribute("data-animated", 'true')

    const scrollerInner = scroller.querySelector(".scroller__inner")! as HTMLElement;
    const scrollerContent = Array.from(scrollerInner.children)

    scrollerContent.forEach((item: any) => {
        const duplicatedItem = item.cloneNode(true);
        duplicatedItem.setAttribute("aria-hidden", true)
        scrollerInner.appendChild(duplicatedItem)
    })
}