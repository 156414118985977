const nextBtn = document.querySelector('.btn-next')! as HTMLButtonElement;
const prevBtn = document.querySelector('.btn-prev')! as HTMLButtonElement;
const steps = document.querySelectorAll('.step')!;
const formSteps = document.querySelectorAll('.form-step')!;
const uploadFileInput = document.querySelector('#uploadFile')!;
import { uploadImage } from '../helpers/button';

if (uploadFileInput) {
  uploadFileInput.addEventListener('change', uploadImage);
}

let currentStep = 1;

nextBtn.addEventListener('click', () => {
  currentStep++;
  if (currentStep > steps.length) {
    currentStep = steps.length;
  }
  updateProgress();
});

prevBtn.addEventListener('click', () => {
  currentStep--;
  if (currentStep < 1) {
    currentStep = 1;
  }
  updateProgress();
});

function updateProgress() {
  steps.forEach((step, index) => {
    if (index === currentStep - 1) {
      step.classList.add('active');
      formSteps[index].classList.add('active');
    } else {
      step.classList.remove('active');
      formSteps[index].classList.remove('active');
    }
  });
  // enable and disable next and prev buttons

  if (currentStep === 1) {
    prevBtn.disabled = true;
  } else if (currentStep === steps.length) {
    nextBtn.disabled = true;
  } else {
    prevBtn.disabled = false;
    nextBtn.disabled = false;
  }
}
